export const en = {

    email: "Endereço de email",
    email_short: "Email",

    email_tip: "O seu endereço de email",
    password: "Password",
    password_tip: "A sua password",
    login: "Entrar",
    help: "Obter ajuda",

    OMV: "M. Veterinário",

    error_authenticating: "Erro na autenticação",

    //Password Recovery and Activation
    password_activate_title: "Ativar conta",
    password_recovery_title: "Recuperar password",

    password_confirm: "Confirmação da password",
    password_confirm_tip: "Repita a password inserida acima",

    set_new_password: "Definir nova password",

    invalid_password: "A password deverá ter pelo menos:",
    invalid_confirm_password: "A password de confirmação deverá ser igual à nova password",

    //Password Recovery tips
    uppercase: "1 letra maiúscula",
    lowercase: "1 letra minúscula",
    digits: "1 número",
    symbols: "1 caracter não alfanumérico",
    min: "6 caracteres",
    max: "25 caracteres",


    //Errors recovery
    session_expired: "Sessão expirada",
    session_expired_msg: "Por razões de segurança a sessão foi expirada. Por favor volte a fazer login",
    password_reset_success: "A password foi alterada com sucesso. Por favor faça login com a nova password.",
    password_forgot_success: "Foi enviado um email com as instruções para alterar a sua password.",
    error_recovery_token: "O email recebido já não é válido. Por favor utilize \"Recuperar password\" e tente novamente",

    //Email Recovery
    password_recovery_send: "Recuperar password",
    email_recovery_description: "Indique o endereço de e-mail associado à sua conta. Irá receber de seguida no seu e-mail as indicações para que possa definir uma nova password.",
    go_to_login: "< Voltar ao login",


    //SERVER ERRORS
    LOGIN_INVALID_CREDENTIALS: "Credenciais inválidas! Por favor verifique o endereço de email e a password e tente novamente!",

    LOGIN_CREDENTIALS_EXPIRED: "A validade da sua password expirou! Por favor use o \"Recuperar password\" para definir uma nova password.",
    LOGIN_MAX_RETRIES_EXCEEDED: "Conta bloqueada! Por favor use o \"Recuperar password\" para definir uma nova password e desbloquear a sua conta.",

    USER_NOT_FOUND: "O email indicado não está associado a nenhuma conta válida.",
    USER_INACTIVE: "A conta está inactiva. Para mais informações contacte os serviços do SIAC.",


    //Associate entities
    entity_choose_help: "Deve escolher a entidade através da qual pretende usar o sistema. Caso opte por aceder sem entidade, as funcionalidades do sistema serão limitadas.",
    entity_name: "Nome da entidade",
    associated_entities: "Entidades autorizadas",
    associate_entity: "Pedir acesso",
    sub_entity: "(Sub-entidade)",

    login_without_entity: "Entrar sem entidade",
    login_with_entity: "Entrar",

    pending: "Pedido pendente",
    remove_association: "Cancelar acesso",
    accept: "Aceitar",
    reject: "Rejeitar",
    associated: "Já está associado",
    cancel: "Cancelar",
    clinical_director: "Diretor clínico",

    no_associated_entities: "Neste momento não está autorizado a aceder a nenhuma entidade. " +
        "Caso pertença a uma entidade deve pedir o acesso à mesma no separador \"Pedir acesso\". Caso deseje pode entrar sem entidade no botão abaixo.",

    CONFIRM_REJECT: "Rejeitar acesso",
    CONFIRM_REJECT_MSG: "Com esta operação vai rejeitar o pedido realizado pela {{name}}.\n\n Deseja realmente rejeitar o pedido recebido?",

    CONFIRM_REMOVE: "Cancelar Acesso",
    CONFIRM_REMOVE_MSG: "O acesso a {{name}} vai ser removido. Como tal, vai deixar de integrar o corpo clínico desta entidade bem como de conseguir efetuar mais registos através da mesma.\n\nPretende realmente cancelar o seu acesso?",

    SUCCESS_DELETE_ASSOCIATION: "Autorização removida",
    SUCCESS_DELETE_ASSOCIATION_MSG: "A autorização com a entidade {{name}} foi removida",

    ERROR_DELETE_ASSOCIATION: "Erro ao apagar a associação",

    SUCCESS_ASSOCIATION: "Acesso aceite",
    SUCCESS_ASSOCIATION_MSG: "Neste momento já pode aceder à entidade {{name}}",

    entity_add_help: "Por favor, indique abaixo o NIF/NIPC da entidade à qual pretende pedir acesso.",
    add_entity_no_results: "Sem Resultados, deve indicar um NIF/NIPC de uma entidade registada no sistema",
    request_association: "Pedir acesso",

    CONFIRM_ASSOCIATE_ENTITY: "Pedir acesso",
    CONFIRM_ASSOCIATE_ENTITY_MSG: "Será enviado um pedido de acesso à entidade {{name}}.\n" +
        "O pedido terá que ser aceite pelo administrador da mesma e poderá levar algum tempo até que esteja concluido.\n\n" +
        "Deseja realmente continuar?",

    CONFIRM_CANCEL: "Cancelar pedido de acesso",
    CONFIRM_CANCEL_MSG: "O pedido de acesso à entidade {{name}} será cancelado.\n Deseja realmente continuar?",

    SUCCESS_REQUEST_ASSOCIATE: "Pedido de acesso",
    SUCCESS_REQUEST_ASSOCIATE_MSG: "Foi enviado um pedido de acesso à entidade {{name}}. Deve aguardar que o mesmo seja validado pelo administrador da entidade à qual o pedido foi feito",

    ERROR_LOGIN_ENTITY: "Erro ao escolher entidade",
    ERROR_LOGIN_ENTITY_MSG: "Não é possível entrar na entidade escolhida. Por favor tente mais tarde.",

    ERROR_OMV_LOGIN: "Erro no login",
    ERROR_OMV_LOGIN_MSG: "Ocorreu um erro no login com a OMV. Por favor tente mais tarde",
    ERROR_OMV_INACTIVE_MSG: "O médico veterinário não está autorizado a aceder ao sistema",

    //OMV
    omv_login_text: "Para realizar o login com a autenticação da Ordem dos Médicos Veterinários deve utilizar o botão abaixo",
    omv_login_btn: "Autenticar com conta OMV",

    //Legal Warning
    accept_legal_warning: "Aceitar condições",

    //help
    close: "Fechar",

    //2FA
    title_2fa: "Necessária confirmação de autenticação",
    description_2fa: "Para sua segurança é necessário validar a sua conta. " +
        "Por favor consulte a sua caixa de email e indique na caixa abaixo o código de 6 dígitos recebido.<br/><br/>Alertamos que o código enviado <strong>expira em {{minutes}} minuto(s) e {{seconds}} segundo(s).</strong>",
    submit_2fa: "Autenticar",

    LOGIN_TWO_FA_MAX_RETRIES_EXCEEDED: "Erro ao autenticar",
    LOGIN_TWO_FA_MAX_RETRIES_EXCEEDED_MSG: "Neste momento o código já nao é válido. Por favor volte a realizar o login para obter um novo código.",

    LOGIN_TWO_FA_INVALID_CODE: "Código inválido",
    LOGIN_TWO_FA_INVALID_CODE_MSG: "Por favor verifique o código introduzido",

    ERROR_2FA_LINK: "Erro a autenticar",
    LOGIN_INVALID_TWO_FA_CREDENTIALS_MSG: "O link utilizado já não se encontra válido. Por favor volte a realizar novamente o login.",
    LOGIN_EXPIRED_TWO_FA_CREDENTIALS_MSG: "O link utilizado já não se encontra válido.",
};

export default en;
