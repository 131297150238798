import React from "react";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import locale from "app/notifications/locales/locales";
import useTranslations from "system/Translations/UseTranslations";
import SingleMenuItem from "layout/modules/BaseLayout/Menu/components/SingleMenuItem";
import {parseNumber} from "system/Utils/parseNumber";


const NotificationMainMenuComponent = props => {

    const {notifications} = props;
    const {t} = useTranslations('remoteNotifications', locale);

    return <SingleMenuItem
        label={t('title')}
        path={"/notifications/"}
        icon={faBell}
        counter={parseNumber(notifications,"0")}
    />

}

export default NotificationMainMenuComponent;
