import {
    faChartLine,
    faCheck,
    faClinicMedical,
    faCreditCard,
    faEnvelope,
    faRandom,
    faTimes,
    faUserMd,
    faMessage, faGears
} from "@fortawesome/free-solid-svg-icons";


const NotificationIcons = {
    ACCOUNT_CHARGE: faCreditCard,
    ANIMAL_TRANSFER_ACCEPT: faCheck,
    ANIMAL_TRANSFER_REJECT: faTimes,
    NEW_TICKET: faEnvelope,
    NEW_TRACEABILITY_TRANSACTION: faRandom,
    ENTITY_ASSOCIATION_REQUEST: faUserMd,
    VETERINARY_ASSOCIATION_REQUEST: faClinicMedical,
    ENTITY_ASSOCIATION_ACCEPT: faCheck,
    VETERINARY_ASSOCIATION_ACCEPT: faCheck,
    REPORT: faChartLine,
    REPORT_FAILED: faChartLine,
    OPERATION_RUN: faGears,
    OPERATION_FAILED: faGears,
    ANIMAL_PRE_REGISTER_ACCEPT: faCheck,
    ANIMAL_PRE_REGISTER_REJECT: faTimes,
    ANIMAL_TRANSFER_MORE_INFO: faMessage,
    ANIMAL_PRE_REGISTER_MORE_INFO: faMessage,

};

const NotificationTranslateLabel = {
    OPERATION_RUN: (notification) => notification.additionalInfo === '-' ? 'OPERATION_RUN_MSG_NO_FILE' : 'OPERATION_RUN_MSG',
}

const NotificationLink = {
    ENTITY_ASSOCIATION_REQUEST: () => "/veterinary-management",
    VETERINARY_ASSOCIATION_REQUEST: () => "/personal/entities",
    NEW_TICKET: (n) => "/support/" + n.objectId,
    ANIMAL_TRANSFER_ACCEPT: (n) => "/animal/view/" + n.additionalInfo,
    ANIMAL_TRANSFER_REJECT: (n) =>  "/transfers/" + n.objectId,
    ANIMAL_TRANSFER_MORE_INFO: (n) =>  "/transfers/" + n.objectId,
    NEW_TRACEABILITY_TRANSACTION: (n) => "/traceability/show/" + n.objectId,
    REPORT: (n) => "/personal/files/" + n.additionalInfo?.replaceAll(" ", "+"),
    OPERATION_RUN: (n) => "/personal/files/" + n.additionalInfo?.replaceAll(" ", "+"),
    ANIMAL_PRE_REGISTER_MORE_INFO: (n) =>  "/pre-registers/" + n.objectId,
    ANIMAL_PRE_REGISTER_REJECT: (n) =>  "/pre-registers/" + n.objectId,
    ANIMAL_PRE_REGISTER_ACCEPT: (n) => "/animal/view/" + n.additionalInfo,
};

export {
    NotificationLink,
    NotificationTranslateLabel
};
export default NotificationIcons;
