import Container from "react-bootstrap/Container";
import {faCheck, faCodeBranch, faSearch, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback, useState} from "react";
import locale from "app/interventions/locales/locales";
import FormContainer from "app/interventions/containers/FormContainer";
import classnames from "classnames";
import useTranslations from "system/Translations/UseTranslations";
import useShowToast from "layout/modules/NotificationArea/utils/useShowToast";
import PopupConfirm from "layout/modules/Popups/PopupConfirm";
import Box from "layout/modules/Box/Box";
import TableHeader from "layout/modules/TableAdvanced/components/header/TableHeader";
import TableHeaderButtonGroup from "layout/modules/TableAdvanced/components/header/TableHeaderButtonGroup";
import UserHasPermission from "system/API/Authentication/UserHasPermission";
import Audit from "app/audit/Audit";
import {AnimalObjectTypes} from "app/audit/utils/AuditObjectTypes";
import {HealthInterventionActions} from "app/audit/utils/AuditActions";
import AnimalOperationAllowed, {AnimalOperations} from "app/animals/utils/AnimalOperationAllowed";
import TableIconAdd from "layout/modules/TableAdvanced/components/buttons/TableIconAdd";
import SearchDataPagination from "modules/GenericTableContainer/SearchDataPagination/SearchDataPagination";
import TableAdvanced from "layout/modules/TableAdvanced/TableAdvanced";
import DateTime from "system/DateTime/DateTime";
import TableIconButton from "layout/modules/TableAdvanced/components/buttons/TableIconButton";

const ListComponent = (props) => {

    const {t} = useTranslations("healthInterventions", locale);
    const showToast = useShowToast();

    const {animal, data, onPageChange, onPageSort, loading, onDelete, reloadBase} = props;

    const [deletedInfo, setDeletedInfo] = useState({});

    const [showAdd, setShowAdd] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    return <Container fluid>

        {(showAdd || showDetail) &&
        <FormContainer
            {...props}
            onClose={() => {
                setShowAdd(false);
                setShowDetail(false)
            }}
            create={showAdd}
            intervention={showDetail}
        />
        }

        <PopupConfirm
            title={t('DELETE_INTERVENTION_CONFIRM')}
            message={t('DELETE_INTERVENTION_CONFIRM_MSG', {name: deletedInfo.name})}
            classConfirm={"danger"}
            visible={!!deletedInfo.id}
            onCancel={useCallback(
                () => setDeletedInfo({}),
                [setDeletedInfo]
            )}
            onConfirm={useCallback(
                () => {
                    setDeletedInfo({})
                    onDelete(
                        deletedInfo.id, {name: deletedInfo.name},
                        () => {
                            if (reloadBase)
                                reloadBase();

                            showToast({
                                title: t('SUCCESS_DELETE_INTERVENTION'),
                                message: t('SUCCESS_DELETE_INTERVENTION_MSG', {name: deletedInfo.name}),
                                icon: faCheck,
                            })
                        },
                        () => showToast({
                            title: t('FAIL_DELETE_INTERVENTION'),
                            message: t('FAIL_DELETE_INTERVENTION_MSG', {name: deletedInfo.name}),
                            className: 'error',
                            icon: faTimes,
                        })
                    );
                },
                [onDelete, setDeletedInfo, deletedInfo.name, deletedInfo.id, showToast, t, reloadBase]
            )}
        />

        <Box>

            <TableHeader>
                <TableHeaderButtonGroup>

                    <UserHasPermission permission={["READ_AUDIT_LOG"]}>
                        <Audit
                            id={"interventions"}
                            lg={true}
                            icon={faCodeBranch}
                            objectId={animal.transponder}
                            affectedObjectType={AnimalObjectTypes.Animal}

                            objectType={[
                                AnimalObjectTypes.HealthIntervention,
                                AnimalObjectTypes.RabiesProphylaxis,
                                AnimalObjectTypes.Sterilization
                            ]}
                            filterActions={HealthInterventionActions}

                        />
                    </UserHasPermission>

                    <AnimalOperationAllowed animal={animal} operation={AnimalOperations.REGISTER_INTERVENTION}>
                        <TableIconAdd
                            className={classnames({"active": showAdd})}
                            onClick={useCallback(() => setShowAdd(true), [setShowAdd])}
                        />
                    </AnimalOperationAllowed>

                </TableHeaderButtonGroup>

            </TableHeader>

            <SearchDataPagination data={data} onPageChange={onPageChange}/>

            <TableAdvanced
                hover
                sort={data?.sortBy}
                order={data?.sortOrder?.toLowerCase()}
                onSortClick={onPageSort}
                loading={(loading || !data)}
            >
                <thead>
                <tr>

                    <th id="executionDate" sorted>
                        {t('execution_date')}
                    </th>

                    <th>
                        {t('type')}
                    </th>

                    <th className={"d-none d-lg-table-cell"}>
                        {t('veterinary')}
                    </th>

                    <th className={"d-none d-lg-table-cell"}>
                        {t('register_date')}
                    </th>

                    <th/>
                </tr>
                </thead>
                <tbody>

                {data?.content?.map((intervention, index) => {
                    return <tr key={index}>


                        <td className={"td-sorted"}>
                            <DateTime format="DD-MM-YYYY" timezone={"UTC"}>
                                {intervention?.executionDate}
                            </DateTime>
                        </td>

                        <td className="fill-empty">
                            {intervention.type?.name}
                        </td>

                        <td className="fill-empty d-none d-lg-table-cell">
                            {intervention.veterinary?.name}
                            {intervention?.createdEntity?.name &&
                                <div className={"table-under-line"}>
                                    {intervention?.createdEntity?.name}
                                </div>
                            }
                        </td>

                        <td className={"d-none d-lg-table-cell"}>
                            <DateTime format="DD-MM-YYYY HH:mm">
                                {intervention?.creationTimestamp}
                            </DateTime>
                        </td>


                        <td className="table-buttons">
                            <div className={"buttons"}>
                                <TableIconButton
                                    icon={faSearch}
                                    className={"table-btn btn-rounded-primary small"}
                                    onClick={() => setShowDetail(intervention)}
                                />


                                <TableIconButton
                                    icon={faTrash}
                                    permission={"DELETE_HEALTH_INTERVENTION"}
                                    onClick={() => {
                                        setDeletedInfo({
                                            id: intervention.id,
                                            name: intervention.type?.name
                                        })
                                    }}
                                    className={"table-btn btn-rounded-danger small"}
                                />
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </TableAdvanced>
            {data?.content?.length === 0 &&
            <div className={"no-table-results"}>
                {t('no_intervention_results')}
            </div>
            }
        </Box>
    </Container>
}

export default ListComponent;
