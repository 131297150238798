import {DayPicker} from "react-day-picker";
import i18next from "i18next";
import {getDateObject, getDateString} from "layout/modules/Forms/DatePicker/utils/Utils";
import React, {useCallback, useMemo} from "react";
import Fade from "react-bootstrap/Fade";
import {enGB, pt} from 'date-fns/locale';
import "react-day-picker/style.css";


const CalendarComponent = props => {

    const {open, setOpen, value, fromDate: fd, toDate: td, onChange} = props;
    const valueDate = useMemo(() => getDateObject(value), [value]);

    const calendarProps = useMemo(
        () => {

            const fromDate = getDateObject(fd);
            const toDate = getDateObject(td);

            return {
                startMonth: !fromDate ? new Date(2000, 0) : new Date(fromDate.getFullYear(), fromDate.getMonth()),
                endMonth: !toDate ? new Date(new Date().getFullYear(), 11) : new Date(toDate.getFullYear(), toDate.getMonth()),
                hidden: [
                    {
                        after: toDate,
                        before: fromDate,

                    }
                ],
            }

        },
        [fd, td]
    );


    return <Fade in={open} unmountOnExit>
        <div className="date-picker-calendar">
            <DayPicker
                mode="single"
                captionLayout="dropdown"
                required
                locale={i18next.language === "pt-PT" ? pt : enGB}
                key={value}
                selected={valueDate}
                defaultMonth={valueDate}
                startMonth={calendarProps?.startMonth}
                endMonth={calendarProps?.endMonth}
                disabled={calendarProps?.hidden}
                days={useMemo(() => ({
                    selected: valueDate,
                }), [valueDate])}
                onSelect={useCallback((day) => {
                    onChange(getDateString(day));
                    setOpen(false);
                }, [setOpen, onChange])}


            />
        </div>
    </Fade>
}

export default CalendarComponent;
