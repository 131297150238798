export const pt = {

    list: "Listagem de animais",
    lost_list: "Animais Perdidos",
    view: "Animal {{id}}",

    create_title: "Registar animal",

    id: "Identificação",

    general: "Dados gerais",

    breed_and_dates: "Raça e data de nascimento",
    specie: "Espécie",
    breed: "Raça",
    location: "Localização",
    status: "Estado",
    transponder: "Transponder",
    owner: "Titular",
    contact: "Contacto",


    //Not found
    register_lost: "Dar animal como perdido",
    no_results: "Não está disponível nenhum registo para apresentar ou a numeração inserida não tem os dígitos corretos (15). Por favor volte a verificar o transponder inserido",
    no_results_with_operations: "O Transponder <strong>{{transponder}}</strong> não está registado",
    register_transponder: "Registar animal",
    register_as_lost: "Dar este animal como perdido",
    origin_title: "Registo de animal perdido",
    origin: "Origem",
    origin_date: "Data do registo",
    OWNERS: "Reportado pelo titular através da aplicação SIAC titulares",
    SIAC: "Reportado via aplicação SIAC",
    PUBLIC: "Reportado via website publico do SIAC",
    closed_by: "Entregue / Fechado por",
    closed_date: "Data de entrega / fecho",
    closed_by_owner: "Dado como entregue/fechado pelo titular através da aplicação SIAC titulares",

    //Filter
    include_sub_entities: "Incluir sub-entidades",
    district: "Distrito",
    county: "Concelho",
    parish: "Freguesia",
    status_ph: "Selecione uma opção",
    limit_external_lost_animals: "Apenas perdidos não registados",
    author: "Registado por",
    approved_by: "Aprovado por",

    //GENDER
    MALE: "Masculino",
    FEMALE: "Feminino",
    UNDEFINED: "Indefinido",

    pending: "Alteração pendente",
    incomplete_data: "Dados incompletos",
    animal_pre_register: "Pré-registo pendente",

    //Status
    NORMAL: "Normal",
    DEAD: "Morto",
    LOST: "Animal desaparecido",
    FOUND: "Animal encontrado",

    table_menu_options: "Opções",
    view_animal: "Ver Registo",
    view_pre_register: "Ver Pré-registo",
    edit_animal: "Editar registo",
    edit_animal_btn: "Editar registo",
    change_owner: "Transmissão de titularidade",
    change_owner_btn: "Transmissão titular",

    compare_animals: "Ver alterações",


    //Operations
    as_lost: "Dar como perdido",
    as_alive: "Anular morte",
    as_dead: "Registar morte",
    delete: "Apagar",
    open_owner: "Ver titular",

    //lost states
    close_process: "Fechar perdido",
    delivered_to_owner: "Devolvido ao titular",
    delivered_to_cro: "Recolhido ao CRO",
    dead: "Morto",
    arrested: "Detido",
    transferred: "Transferido",
    close_situation: "Situação",
    registered_by_error: "Registado por erro",
    close_lost_with_transfer_msg: "Para fechar o processo como \"{{type}}\" deve primeiro preencher o pedido de transmissão de titularidade para o novo titular." +
        "<br/>Após a submissão do pedido e a respetiva aprovação o processo de animal perdido será dado como resolvido/fechado.",

    transfer_is_from_lost_close: "Pedido associado a processo de animal perdido",
    transfer_is_from_lost_close_msg: "Este pedido está associado a um fecho de processo de animal perdido com o motivo \"<b>{{status}}</b>\".",

    lost_close_is_pending: "Fecho de processo pendente",
    lost_close_is_pending_msg: "Neste momento encontra-se pendente uma transmissão de tiularidade que irá, caso aprovada, fechar este processo de perdido. Deve aguardar a validação dos serviços do SIAC.",

    //Side Bar
    anti_rabies: "Prof. antirrábica",
    sterilize: "Esterilização",
    set_status: "Estado",
    operations: "Operações",
    shortcuts: "Registar",

    rabies_until: "Até {{date}}",
    no_anti_rabies: "Sem imunidade",

    not_sterilized: "Não realizada",
    is_sterilized: "Realizada",

    //Msgs
    animal_is_locked: "Animal bloqueado",
    animal_is_locked_msg: "O registo deste animal está bloqueado pelos serviços do SIAC e apenas pode ser usado para um conjunto limitado de funcionalidades.<br/>Para mais informações ou qualquer intervenção no registo contate os serviços do SIAC.\n",

    animal_is_not_valid: "Dados inválidos",
    animal_is_not_valid_msg: "O registo deste animal está incompleto uma vez que foram detetados os seguintes erros:<br/><br/>{{errors}}<br/><br/><strong>O conjunto de funcionalidades autorizadas é limitado pelo que se desejar imprimir o DIAC ou realizar outro operação deve realizar um \"Novo pedido de suporte\"</strong>",
    animal_is_not_valid_edit_msg: "O registo deste animal está incompleto uma vez que foram detetados os seguintes erros:<br/><br/>{{errors}}<br/><br/><strong>Para imprimir o DIAC ou realizar outro tipo de operações deverá EDITAR o registo e preencher os campos assinalados.</strong>",


    animal_is_dead: "Animal morto",
    animal_is_dead_msg: "Este animal foi dado como morto no dia {{deathDate}}.",
    animal_is_dead_msg_no_date: "Este animal foi dado como morto",

    animal_is_pending_owner: "Transmissão de titularidade",
    animal_is_pending_owner_msg: "Não é possível editar os dados deste animal já que foi feito um pedido de transmissão de titularidade que ainda se encontra pendente de aprovação por parte dos serviços.",
    animal_is_pending_owner_msg_link: "Para visualizar o processo carregue aqui.",


    MISSING_OWNER: "Não existe nenhum dado para o titular",

    //Form
    animal_id: "Identificação do animal",
    transponder_ph: "Transponder",
    order_number: "Nº de ordem",
    registration_date: "Data de registo",
    date_ph: "Data no formato (dd-mm-yyyy)",
    order_number_ph: "Introduza para definir manualmente",
    health_report: "Boletim sanitário",
    health_report_ph: "O nº do boletim sanitário",

    animal_data: "Dados do animal",
    name: "Nome",
    name_ph: "O nome do animal (ex. Boby)",
    passport: "Nº de passaporte",
    passport_ph: "Nº de passaporte (ex. PTXXXXX)",
    birthdate: "Data de nascimento",
    gender: "Sexo",
    gender_ph: "Selecione uma opção",
    is_cross_breed: "Tem cruzamento?",
    cross_breed: "Raça cruzada",
    category: "Tipo",
    color: "Cor",
    color_ph: "A cor do animal (ex. Preto)",
    observations: "Observações",

    has_pedigree: "Pedigree",
    pedigree: "Pedigree - Livro de Origens Português (LOP)",
    animal_address: "Morada do animal",
    use_owner_address: "Utilizar morada do titular",

    lop_number: "Número de registo no LOP",
    lop_number_ph: "Introduza o número constante no LOP",
    lop_name: "Nome registado no LOP",
    lop_name_ph: "Introduza o nome inscrito no LOP",

    veterinary_and_entity: "Entidade e m. veterinário responsáveis pelo registo",
    veterinary: "M. veterinário",
    entity: "Entidade",

    upload_photo: "Escolher foto",
    create_form: "Registar animal",
    save_form: "Guardar alterações",

    unlock_animal: "Desbloquear registo",
    lock_animal: "Bloquear registo",

    //Set Death
    confirm: "Confirmar",
    cancel: "Cancelar",
    date_of_death: "Data da morte",
    set_dead_alert: "Atenção",
    set_dead_alert_msg: "Esta operação irá bloquear qualquer tipo de alteração do registo e não poderá ser revertida pelo que deve apenas confirmar no caso do animal estar realmente morto.",
    please_set_dead_date: "Por favor indique na caixa abaixo a data efetiva de morte do animal.",

    SET_ALIVE_CONFIRM: "Confirmação",
    SET_ALIVE_CONFIRM_MSG: "Esta operação irá reverter a morte do animal. Deseja realmente continuar?",

    SUCCESS_SET_DEAD: "Morte registada",
    SUCCESS_SET_DEAD_MSG: "Foi definida a data de morte para o animal com o transponder {{transponder}} com sucesso",

    SUCCESS_SET_ALIVE: "Morte Revertida",
    SUCCESS_SET_ALIVE_MSG: "O animal com o transponder {{transponder}} está novamente dado como vivo!",

    ERROR_SUCCESS_SET_ALIVE: "Erro",
    ERROR_SUCCESS_SET_ALIVE_MSG: "Ocorreu um erro. Por favor tente mais tarde",

    //Errors
    FORM_ERROR: "Erro no formulário",
    FORM_ERROR_MSG: "Por favor verifique os campos assinalados",
    OWNER_IS_LOCKED: "O titular do registo não está autorizado a registar novos animais. Por favor entre em contacto com os serviços",

    UPDATE_SUCCESS: "Registo atualizado",
    UPDATE_SUCCESS_MSG: "O registo do animal \"{{name}}\" foi atualizado com sucesso!",

    CREATE_SUCCESS: "Animal registado",
    CREATE_SUCCESS_MSG: "O animal \"{{name}}\" foi registado com sucesso!",

    LOCK_ANIMAL_CONFIRM: "Confirmação",
    LOCK_ANIMAL_CONFIRM_MSG: "O registo vai ser bloqueado e algumas operações como a impressão do DIAC não vão ser possíveis.\n\nDeseja realmente continuar?",
    UNLOCK_ANIMAL_CONFIRM_MSG: "O registo vai ser desbloqueado. Deseja realmente continuar?",

    SUCCESS_LOCKING_ANIMAL: "Bloqueio de registo",
    SUCCESS_LOCKING_ANIMAL_MSG: "O registo do animal {{transponder}} foi <strong>bloqueado</strong> com sucesso",
    SUCCESS_UNLOCKING_ANIMAL_MSG: "O registo do animal {{transponder}} foi <strong>desbloqueado</strong> com sucesso!",


    MISSING_TRANSPONDER: "Transponder em falta",
    MISSING_NAME: "Nome do animal em falta",
    MISSING_BIRTHDATE: "Data de nascimento em falta",
    INVALID_BIRTHDATE: "Data de nascimento inválida",
    MISSING_GENDER: "Sexo do animal em falta",
    MISSING_BREED: "Raça em falta",
    MISSING_COLOR: "Cor em falta",
    MISSING_VETERINARY: "M. veterinário em falta",
    MISSING_ENTITY: "Entidade de registo em falta",
    INVALID_DATE: "Por favor indique uma data válida",
    MISSING_LOP_NAME: "Nome lop (pedigree) em falta",
    MISSING_LOP_NUMBER: "Nº de lop (pedigree) em falta",
    MISSING_POSTAL_CODE: "Código postal inválido",

    NO_CREDITS: "A sua conta não dispõe de créditos suficientes para o registo animal.",

    //death Validation
    MISSING_DEATH_TIMESTAMP: "Indique a data de falecimento",

    //Transponder Validation Errors
    TRANSPONDER_NOT_AVAILABLE_FOR_ENTITY: "Este transponder não está rastreado para a sua entidade.",
    TRANSPONDER_ALREADY_REGISTERED: "Este transponder já se encontra registado",
    CANNOT_VALIDATE_TRANSPONDER: "Problema a validar transponder. Por favor tente mais tarde",
    COULD_NOT_VALIDATE_ON_TRACEABILITY: "Neste momento não é possível validar este transponder. Por favor tente mais tarde ou faça um pre registo",
    CANNOT_REGISTER_UNUSABLE_TRANSPONDER: "Este transponder não pode ser utilizado. Para mais informações contacte os serviços SIAC",

    //Owner Check
    OWNER_DATA_LOADED: "Dados do titular",
    OWNER_DATA_LOADED_MSG: "Foram carregados automaticamente os dados do titular {{name}}. Caso exista algum dado que esteja incorreto ou desatualizado pode corrigir no formulário",
    OWNER_MERGE_TITLE: "Titular com NIF já registado",
    OWNER_MERGE_MSG: "Um titular com o NIF {{vatNumber}} e o nome {{name}} já se encontra registado.\n" +
        "Por favor verifique nos campos abaixo se se trata do mesmo titular.Em caso afirmativo as duas fichas serão fundidas. No caso de não se tratar do mesmo titular será aberto um pedido de suporte para os serviços.",

    owner_merge_reject: "Não é o mesmo titular. ",
    owner_merge_accept: "Sim é o mesmo titular. Continuar a editar.",
    owner_merge_accept_remote: "Sim é o mesmo titular. Continuar com esta versão",

    files: "Documentos",
    other_documents: "Outros documentos",

    //Lost
    animal_is_lost: "Este animal está desaparecido!",
    animal_is_found: "Este animal foi encontrado por terceiros",


    animal_is_lost_msg: "<strong>Local do desaparecimento</strong>: {{local}}<br/><strong>Data:</strong> {{date}}" +
        "<br/><br/>No caso do animal já ter sido entregue por favor utilize a opção \"Devolvido ao titular\"",

    animal_is_found_msg: "<strong>Encontrado em:</strong>  {{local}} <br/><strong>Data:</strong> {{date}}" +
        "<br/><br/>No caso do animal já ter sido entregue por favor utilize a opção \"Devolvido ao titular\"",

    view_lost_info: "Ver processo",
    close: "Fechar",

    //Lost Form
    select_status_msg: "Por favor indique se o animal está desaparecido ou se foi encontrado",
    lost_set_lost: "Este animal está desaparecido",
    lost_set_lost_msg: "Neste momento não se sabe do paradeiro do animal",

    lost_set_found: "Este animal foi encontrado por terceiros",
    lost_set_found_msg: "Este animal foi recolhido e identificado.",

    lost_local: "Local da ocorrência",
    lost_animal_situation: "Situação do animal",
    lost_animal_situation_ph: "Escolha a situação do animal",
    event_country: "País da ocorrência",
    occurrence_date: "Data da ocorrência",
    participant_data: "Informação do participante (quem perdeu/encontrou)",
    participant_name: "Nome",
    participant_name_alt: "Nome do participante",
    participant_contact: "Contacto telefónico",
    participant_contact_alternative: "Contacto alternativo",
    participant_email: "Email",
    closed_title: "Informação de entrega",
    email_ph: "Escreva um email (opcional)",
    participant_name_ph: "O nome do participante",
    local_ph: "O local da ocorrência (ex. Lisboa)",
    legal_allow_title: "Autorizações",
    allow_advertising: "Permitir divulgação pública",
    allow_advertising_msg: "Declaro que autorizo a divulgação pública da informação deste processo de recuperação de animal perdido, incluindo a minha identificação e os meus dados de contacto, na forma de anúncio, com acesso público, para publicitar a situação relativa ao animal e permitir o contacto para sua recuperação." +
        "Esta informação ficará disponível em www.siac.vet, podendo, a qualquer momento, solicitar que deixe de estar disponível publicamente.",
    lost_main_animal_photo: "Fotografia principal do animal",
    lost_additional_animal_photo: "Fotografias adicionais",
    use_as_animal_photo: "Autorizo adicionar a fotografia do processo de animal perdido ao registo permanente do animal.",
    use_animal_photo_in_process: "Autorizo associar a fotografia do registo SIAC a este processo de animal perdido.",

    additional_photos_file_upload: "Caso deseje, pode carregar mais fotografias do animal.<br>Deve escolher ou arrastar um máximo de 4 fotografias adicionais.",
    click_to_delete: "Carregue para remover esta foto",

    //Animal Situations
    SHELTER: "Acolhimento",
    STREET: "Rua",
    KENNEL: "Canil municipal",
    CAMV: "CAMV",
    WITH_PARTICIPANT: "Com o participante",
    OTHER: "Outra",

    upload_lost_file_label: "Caso deseje, pode fazer o upload de uma fotografia do animal ou carregar uma fotografia do animal.<br>Em caso afirmativo, escolha ou arraste uma fotografia para esta caixa.",

    LOST_ANIMAL_CLOSE: "Dar como entregue / fechado",
    LOST_ANIMAL_CLOSE_MSG: "O Processo de animal perdido será fechado para o transponder {{transponder}}.\r\nTem realmente a certeza que o animal foi {{status}}?",

    SUCCESS_SET_CLOSED: "Animal dado como entregue",
    SUCCESS_SET_CLOSED_MSG: "Foi fechado o processo de animal perdido para animal com o transponder {{transponder}}",

    ERROR_SET_LOST: "Erro ao dar como desaparecido. Por favor entre em contacto com os serviços SIAC",
    ERROR_SET_CLOSED: "Erro ao dar como entregue",
    ERROR_SET_CLOSED_MSG: "Ocorreu um erro ao dar o animal como entregue. Por favor tente mais tarde",
    ANIMAL_IS_LOCKED: "O animal está bloqueado!",

    //lost list
    LOST_STATUS_NORMAL: "Perdido",
    LOST_STATUS_LOST: "Desaparecido",
    LOST_STATUS_FOUND: "Encontrado",

    lost_registers: "Histórico Perdido",
    closed_occurrence: "Processo fechado",
    registered: "Animais registados",
    from_creation_date: "Data de registo desde",
    to_creation_date: "Data de registo até",
    from_occurrence_date: "Data de ocorrência desde",
    to_occurrence_date: "Data de ocorrência até",


    //list card
    external_animal: "Não está registado",

    //Errors
    INVALID_SITUATION: "Deve indicar a situação atual do animal",
    MISSING_LOCAL: "Por favor indique o local",
    MISSING_DATE: "Por favor indique a data",
    MISSING_PARTICIPANT_NAME: "Por favor indique o nome do participante",
    MISSING_PARTICIPANT_CONTACT: "Por favor indique o contacto do participante",
    MISSING_COUNTY: "Indique um concelho",
    INVALID_DISTRICT: "Indique um distrito",

    SUCCESS_SET_LOST_FOUND: "Animal encontrado",
    SUCCESS_SET_LOST_FOUND_MSG: "O animal com o transponder {{transponder}} foi dado como encontrado!",

    SUCCESS_SET_LOST_LOST: "Animal desaparecido",
    SUCCESS_SET_LOST_LOST_MSG: "O animal com o transponder {{transponder}} foi dado como desaparecido!",

    //health_interventions
    health_interventions: "Intervenções sanitárias",
    register_intervention: "Adicionar intervenção sanitária",

    //Licensing
    licensing: "Licenciamento",
    invalid_license: "Sem licenciamento",
    valid_license: "Licenciamento válido",

    //aggressions
    aggressions: "Agressões",

    //Merge
    merge_animal: "Fusão de animal",
    animal_to_merge: "Dados do animal a fundir",
    merge_data: "Dados da fusão",

    merge_description: "Por favor indique o transponder, que será fundido na ficha atual e a razão da fusão.",
    old_transponder: "Transponder a fundir",
    merge_reason: "Motivo da fusão",
    merge: "Fundir",

    SUCCESS_MERGE: "Fusão realizada",
    SUCCESS_MERGE_MSG: "Foi realizada a fusão entre os transponders <strong>{{t1}}</strong> e <strong>{{t2}}</strong> com sucesso!",

    INVALID_TRANSPONDER: "O transponder não é válido",
    MISSING_REASON: "Deve indicar o motivo da fusão",

    animal_has_merged_transponders: "Animal com múltiplos transponders",
    animal_has_merged_transponders_msg: "Este animal foi também identificado com os seguintes transponders:<br/><br/><strong>{{transponder}}</strong> - Principal<br/><br/>{{entries}}",
    merge_entry: "<strong>{{oldTransponder}}</strong><br/>" +
        "Registado a {{registrationDate}} pelo m. veterinário {{veterinaryBallot}} - {{veterinaryName}}<br/>" +
        "Motivo de Fusão: {{reason}}",

    transponder_free: "Registo sem consumos",
    transponder_free_msg: "O registo relativo a este transponder não tem custos associados porque o mesmo faz parte de uma campanha promovida por entidade oficial.",

    transponder_n: "Transponder {{n}}",

    animal_lost_pending_one: "Existe um pedido de animal perdido pendente",
    animal_lost_pending_other: "Existem {{count}} pedidos de animal perdido pendentes",

    animal_lost_pending_msg_one: "Existe um pedido de animal pendente perdido que deve ser validado. Para aceder ao pedido carregue ",
    animal_lost_pending_msg_link: "aqui.",

    animal_lost_pending_msg_other: "Existem {{count}} pedidos de animal pendente perdido que devem ser validados.",

    no_credits: "Não dispõe de saldo",
    no_credits_msg: "Neste momento não dispõe de saldo para realizar o registo de animais regulares pelo que só será possível registar ou pré-registar animais com transponders associados a campanha.",

    NO_CREDIT: "Neste momento não dispõe de saldo para realizar o registo deste transponder",
    CANNOT_REGISTER_FREE_TRANSPONDER: "Não está autorizado a registar este transponder. Por favor contacte o suporte.",
    TRANSPONDER_NOT_BILLABLE: "Este transponder está associado a uma campanha e o seu registo não tem consumos. Deve realizar um pré-registo.",
    TRANSPONDER_IN_PENDING_TRANSACTION_TO_REGISTER_ENTITY: "Este transponder está pendente na sua entidade. Para registar este transponder deverá primeiro aceitar a transação pendente.",
    TRANSPONDER_IN_PENDING_PRE_REGISTER: "Este transponder tem um pré-registo pendente. Deve aguardar que o registo seja aprovado ou rejeitado.",

    //Set photo popup
    set_photo: "Guardar fotografia",
    select_photo: "Por favor arraste a nova fotografia do animal para esta caixa ou utilize o botão escolher foto",
    select_photo_mobile: "Por favor  utilize o botão escolher foto para selecionar ou tirar uma nova foto",
    clear_photo: "Apagar fotografia",

    SUCCESS_SET_PHOTO: "Fotografia alterada",
    SUCCESS_SET_PHOTO_MSG: "Foi atualizada a fotografia do animal {{transponder}} com sucesso!",


    //Breed popup
    breed_popup_title: "Aviso",


    //process observations
    process_observations: "Novas observações do processo",
    observation: "Nova observação",
    create: "Criar",
    SUCCESS_CREATED_OBSERVATION: "Observação criada",
    SUCCESS_CREATED_OBSERVATION_MSG: "Foi registada com sucesso uma observação neste processo de animal perdido",
    DELETE_OBSERVATION_CONFIRM: "Confirmação",
    DELETE_OBSERVATION_CONFIRM_MSG: "Deseja realmente apagar esta observação?",
    SUCCESS_DEL_OBSERVATION: "Sucesso",
    SUCCESS_DEL_OBSERVATION_MSG: "Observação apagada com sucesso",
    MISSING_OBSERVATION: "Deve indicar a observação",
    no_process_observation: "Não existem observações para este processo. Caso queira adicionar uma observação utilize o botão \"+\".",


};

export default pt;
