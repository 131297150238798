const interventionResolver = (intervention) => ({
    ...intervention,
    typeId: intervention.type?.id,
    vaccineId: intervention.vaccine?.id,
    veterinaryId: intervention.veterinary?.id,
    financingEntityId: intervention.financingEntity?.id
});


const interventionAuditResolver = (intervention) => ({
    ...intervention,
    typeId: intervention?.type,
    vaccineId: intervention?.rabiesVaccine,
    financingEntityId: intervention?.financingEntity?.id,
    veterinary: {
        id: intervention?.veterinaryId
    },
});


export default interventionResolver;

export {interventionAuditResolver};
