import React from "react";
import ReportParameter from "../params/ReportParameter";
import Row from "react-bootstrap/Row";
import Box from "layout/modules/Box/Box";
import BoxTitle from "layout/modules/Box/components/BoxTitle";
import {getOrNullSafe} from "system/Objects/ObjectParameters";
import classnames from "classnames";
import {parseBoolean} from "system/Utils/parseBoolean";

const ParamsComponent = props => {

    const {t, errors: formErrors, setRequest, request, template, readOnly} = props;
    const errors = formErrors['parameters'] ? formErrors['parameters'] : {};

    return <Box className={classnames({"view-mode": readOnly})}>
        <BoxTitle>
            {t('report_params')}
            {template.needsOneParam && <>*</>}
        </BoxTitle>

        <Row>
            {template?.parameters?.map((parameter, index) => {
                    const parameterName = "parameter_" + parameter?.id;
                    const error = errors[parameterName];
                    return <ReportParameter
                        t={t}
                        key={index}
                        readOnly={readOnly}
                        parameter={{
                            ...parameter,
                            name: parameterName,
                        }}
                        error={error ? t(error) : undefined}
                        value={request?.parameters
                            ? parseBoolean(
                                request?.parameters[parameterName],
                                getOrNullSafe(request?.parameters[parameterName])
                            )
                            : null
                        }
                        setValue={(value) => setRequest(request => ({
                            ...request,
                            parameters: {
                                ...request?.parameters,
                                [parameterName]: value,
                            }
                        }))}
                    />
                }
            )}
        </Row>

    </Box>

}

export default ParamsComponent;
