import classnames from "classnames";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {themes, useThemeState} from "system/Themes/ThemeContextWrapper";
import {generateOnClickAndEnter} from "system/Acessibility/handleKeyboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSun} from "@fortawesome/free-solid-svg-icons/faSun";
import {faMoon} from "@fortawesome/free-solid-svg-icons/faMoon";
import "layout/modules/BaseLayout/Menu/css/dark-mode-btn.scss";

const DarkModeComponent = props => {

    const {t} = useTranslation('global');

    const {changeTheme, theme} = useThemeState();
    const isDarkMode = theme === themes.dark;

    return <div aria-details={t('dark_mode')}
                className={"dark-mode-button"}  {...generateOnClickAndEnter(useCallback(
        () => changeTheme((darkMode) => darkMode ? themes.light : themes.dark),
        [changeTheme]
    ))}>

        <div className={classnames("mode", {"active": !isDarkMode})}>
            <FontAwesomeIcon icon={faSun}/>
            <div className={"label"}>
                {t('light')}
            </div>
        </div>

        <div className={classnames("mode", {"active": isDarkMode})}>
            <FontAwesomeIcon icon={faMoon}/>
            <div className={"label"}>
                {t('dark')}
            </div>
        </div>

    </div>


}

export default DarkModeComponent;
