import BoxTitle from "layout/modules/Box/components/BoxTitle";
import Box from "layout/modules/Box/Box";
import React, {useCallback, useMemo} from "react";
import MultiOptionComponent from "layout/modules/Forms/MultiOptionControl/MultiOptionComponent";
import ParamComponent from "./ParamComponent";
import BoxOptions from "layout/modules/Box/components/BoxOptions";
import {getOrDefault} from "system/Objects/ObjectParameters";
import CheckBox from "layout/modules/Forms/CheckBox/CheckBox";

const ParamsComponent = props => {

    const {errors, t, template, setTemplate, readOnly, isOperation} = props;
    const hasMandatoryParams = useMemo(
        () => template?.parameters?.some(p => p.mandatory),
        [template?.parameters]
    );

    return <Box>
        <BoxTitle>{t('template_params_config')}</BoxTitle>
        <BoxOptions visible={!hasMandatoryParams}>
            <CheckBox
                value={getOrDefault(template?.needsOneParam, false)}
                label={t('needs_one_param')}
                disabled={readOnly}
                onChange={(val) => setTemplate(template => ({
                    ...template,
                    needsOneParam: val,
                }))}
            />
        </BoxOptions>

        <MultiOptionComponent
            data={template?.parameters}
            errors={errors?.parameters}
            readOnly={readOnly}
            isOperation={isOperation}
            onChange={useCallback((fnc) => setTemplate((data) => ({
                ...data,
                parameters: fnc(data.parameters),
            })), [setTemplate])}>
            <ParamComponent t={t} isOperation={isOperation}/>
        </MultiOptionComponent>
    </Box>

}

export default ParamsComponent;
